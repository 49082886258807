import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { componentLabels } from 'utils/constants';
import Logo from 'assets/images/logo/logo.png';
import { deleteCookie } from 'utils/cookie';
import { HiExternalLink } from 'react-icons/hi';

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1536px;
  margin: 0 2rem 0 2rem;
`;
const DropdownUser = styled.div`
  position: relative;
  & div:nth-child(2) {
    //right: 0;
  }
`;

const Navbar = () => {
  const ref = useRef(null);
  const { user, logout } = useAuth0();
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const handleLogout = () => {
    deleteCookie('userId');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleOpenReporting = () => {
    window.open('https://reporting.worthvs.com/reports', '_blank');
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isShowDropDown]);

  return (
    <div className="w-full flex justify-center relative">
  <NavbarContainer className="py-6 flex items-center justify-between w-full max-w-screen-lg">
    <img className="max-h-60px" src={Logo} alt="mres" />

    {/* Centered Text with more right adjustment */}
    <div className="absolute left-1/2 transform -translate-x-1/2 text-center ml-4">  {/* Adjusted with larger margin */}
      <span className="font-semibold text-xl hidden md:inline-flex">
        WVS - Single Pane of Glass
      </span>
    </div>

    {/* Right-side Controls */}
    <div className="flex items-center space-x-2">
      <button 
        className='clickable p-2 text-sm flex items-center space-x-1' 
        data-testid="test-reports" 
        onClick={handleOpenReporting} 
        style={{ cursor: 'pointer', maxWidth: '150px' }}
      >
        <span className='whitespace-nowrap'>View Reports</span>
        <HiExternalLink className="w-4 h-4" />
      </button>

      <DropdownUser id="dropdown-user">
        <div
          id="dropdown-user-text"
          ref={ref}
          data-testid="test-dropdown"
          className="flex items-center cursor-pointer"
          onClick={() => setIsShowDropDown(!isShowDropDown)}
        >
          <div className="avatar">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.228 3.15203C9.228 1.41801 7.8181 0.0040602 6.08408 8.75991e-06C4.34601 -0.00404268 2.93611 1.39776 2.92801 3.13988C2.91585 4.86984 4.34196 6.3 6.07598 6.3C7.80594 6.3 9.22395 4.882 9.228 3.15203ZM11.872 12.0736V10.8654C11.7664 10.0205 11.4073 9.27278 10.8582 8.62643C9.90342 7.49849 8.66987 6.94931 7.19552 6.93663C6.39712 6.92818 5.59872 6.93241 4.80032 6.93663H4.80023C4.26794 6.94086 3.74833 7.00845 3.24139 7.18165C2.2782 7.51539 1.49667 8.09837 0.905238 8.9306C0.50391 9.49668 0.229318 10.1219 0.12793 10.7978V12.0736C0.12793 13.134 0.993952 14 2.0543 14H9.94567C11.006 14 11.872 13.134 11.872 12.0736Z" fill="white"/>
            </svg>
          </div>
          <svg
            className="ml-2 w-4 h-4"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        <div
          id="dropdown-user-item"
          className={`${
            !isShowDropDown && 'hidden'
          } dropdown-user divide-y divide-gray-100 shadow`}
        >
          <ul
            className="py-1 text-sm text-gray-900 dark:text-gray-200 w-full"
            aria-labelledby="dropdownDefault"
          >
            <li>
                {user?.name}
            </li>
            <li className='clickable' data-testid="test-reports" onClick={handleOpenReporting}>
              <div className='flex items-center justify-between'>
                {componentLabels.NAVBAR.REPORT_LABEL} <HiExternalLink />
              </div>
            </li>
            <li className='clickable' data-testid="test-logout" onClick={handleLogout}>
                {componentLabels.NAVBAR.LOGOUT_LABEL}
            </li>
          </ul>
        </div>
      </DropdownUser>
    </div>
  </NavbarContainer>
</div>

  );
};

export default Navbar;
